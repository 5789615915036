import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import richTextOptions from "~utils/rich-text-options"
import { FormattedMessage } from "react-intl"
import Layout from "~components/_layout"

const FAQPage = ({ data }) => (
  <Layout
    showHeader={false}
    styleProps={{
      backgroundColor: data.contentfulCustomer.backgroundColor,
      backgroundImage: data.contentfulCustomer.backgroundImage,
    }}
  >
    <div className="section">
      <div className="container">
        <h1 className="has-text-white margin-bottom-32">
          <FormattedMessage id="faq.title" />
        </h1>

        {(data.contentfulCustomer.faq || []).map((val, i) => (
          <div className="box" key={i}>
            <h4 className="margin-bottom-8">{val.question}</h4>

            <div className="content">
              {documentToReactComponents(val.answer.json, richTextOptions)}
            </div>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export default FAQPage

export const pageQuery = graphql`
  query($customerId: Int) {
    contentfulCustomer(customerId: { eq: $customerId }) {
      backgroundColor
      backgroundImage {
        file {
          url
        }
      }
      faq {
        question
        answer {
          json
        }
      }
    }
  }
`
